.list {
    display: flex;
    min-height: 100vh;
}

.listContainer {
    flex: 1;
}

.form-container {
    min-width: 95%;
    margin: 0 auto;
    padding: 20px;

    h2 {
        text-align: center;
        margin: 20px;
        text-transform: uppercase;
    }

    .form-group {
        margin-bottom: 20px;
    }

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 8px;
    }

    input[type="text"],
    input[type="date"],
    input[type="number"] {
        width: 100%;
        padding: 10px;
        height: auto;
        margin-bottom: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        overflow-y: scroll;
        resize: none;
        /* allow vertical resizing */
    }

    textarea {
        width: 100%;
        padding: 10px;
        height: auto;
        margin-bottom: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        overflow-y: hidden;
        resize: none;

        &:focus {
            overflow-y: auto;
        }
    }

    input[type="checkbox"] {
        padding: 10px;
        margin-bottom: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .pack-details {
        margin-bottom: 20px;
    }

    h3 {
        text-align: center;
        text-transform: uppercase;
        margin: 20px;

    }

    .btn {
        display: inline-block;
        padding: 12px 20px;
        margin-top: 20px;
        background-color: #0070c9;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin: 10px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #005191;
        }
    }
}