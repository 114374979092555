// .list {
//     display: flex;
//     align-items: stretch;

//     .listContainer {
//         flex: 1;
//         display: flex;
//         flex-direction: column;
//     }
// }

.country-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    margin: auto;
    // border: 1px solid #ccc;

    h2 {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
    }

    form {
        h3 {
            font-size: 20px;
            margin-top: 20px;
            // border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
        }

        div {
            margin: 20px;

            input {
                width: 100%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;

                &:focus {
                    border-color: #007bff;
                }
            }

            button {
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                padding: 10px 20px;
                margin-top: 10px;
            }
        }

        button[type="button"] {
            background-color: #007bff;
        }

        button[type="submit"] {
            width: 100%;
            margin: 15px auto;
            background-color: #4caf50;
        }
    }
}